/* eslint-disable @next/next/no-img-element */
import SearchBC from '@components2/SearchBC';
import { useMobxStores } from '@stores/index';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import ButtonSearchBC from './ButtonSearchBC';
import { homeAbTest } from '@/utils/gaTools';

function HomeSearchBC({ airports, deviceType }) {
  const { uiStore } = useMobxStores();
  const kayakDiv = useRef();

  useEffect(() => {
    if (uiStore.searchSwitchTerm === 'hotel' && kayakDiv.current) {
      const kayakDiv = document.getElementById('kayak');
      const script = document.createElement('script');
      script.src = 'https://www.kayak.com/search-widget/script/direct/kayak';
      kayakDiv.appendChild(script);
    }
  }, [uiStore.searchSwitchTerm, kayakDiv]);

  const [testScope, setTestScope] = useState(null);

  useEffect(() => {
    setTestScope(homeAbTest());
  }, []);

  return (
    <>
      <div
        className={`h-[31rem] z-10 flex flex-col justify-center items-center relative
          ${
            uiStore.searchSwitchTerm === 'airport'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-airportAB'
                    : 'bg-search-airportAB-mobile'
                }`
              : uiStore.searchSwitchTerm === 'cruise'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-cruiseAB'
                    : 'bg-search-cruiseAB-mobile'
                }`
              : uiStore.searchSwitchTerm === 'city'
              ? `${
                  deviceType === 'desktop'
                    ? 'bg-search-cityAB'
                    : 'bg-search-cityAB-mobile'
                }`
              : `${
                  deviceType === 'desktop'
                    ? 'bg-search-hotelAB'
                    : 'bg-search-hotelAB-mobile'
                }`
          }
            `}
      >
        <div className="container mx-auto px-4 md:px-16  ">
          <h2 className="table mx-auto text-2xl md:text-4xl text-center text-white font-bold px-auto px-2 rounded-2xl shadow-searchAB">
            Reserve premium parking at affordable rates.
          </h2>
          <h2 className="table mx-auto text-1xl md:text-2xl text-center text-white font-bold px-auto pb-2 rounded-2xl shadow-searchAB">
            Park, Save, Relax!
          </h2>
          <div className="bg-white bg-opacity-90 md:bg-opacity-95 rounded-2xl py-6 px-8 md:px-20 w-full md:w-3/5 mx-auto shadow-md h-[23.5rem]">
            <div className="z-20">
              <ButtonSearchBC testScope={testScope} />
              {uiStore.searchSwitchTerm === 'airport' ? (
                <SearchBC
                  key="aa"
                  searchType={'airport'}
                  airports={airports}
                  deviceType={deviceType}
                />
              ) : uiStore.searchSwitchTerm === 'cruise' ? (
                <SearchBC
                  key="ab"
                  searchType={'cruise'}
                  airports={airports}
                  deviceType={deviceType}
                />
              ) : uiStore.searchSwitchTerm === 'city' ? (
                <SearchBC searchType="city" deviceType={deviceType} />
              ) : (
                testScope === 1 && (
                  <div
                    ref={kayakDiv}
                    id="kayak"
                    data-affiliate-id="kan_316098_592174"
                    data-theme="light"
                    data-language-code="en"
                    data-country-code="US"
                    data-currency-code="USD"
                    data-vertical="hotels"
                    data-vertical-list="hotels"
                    data-fill-trip-type="2"
                    data-label="home"
                    data-disable-compare-to="true"
                    data-auto-height="true"
                    className="mt-2"
                  ></div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(HomeSearchBC);
