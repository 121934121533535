import { CDN2 } from '@/helpers/api';

export const airportCarousel = [
  {
    price: 4.99,
    name: 'Denver',
    fullName: 'Denver International Airport (DIA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Denver.webp',
    hrefCard: 'Denver-International-Airport-DIA',
  },
  {
    price: 13.5,
    name: 'Chicago',
    fullName: "Chicago O'Hare International Airport (ORD)",
    imgCard: CDN2() + '/staticmyapp/carouselairports/Chicago.webp',
    hrefCard: 'Chicago-International-Airport-ORD',
  },
  {
    price: 3.99,
    name: 'Atlanta',
    fullName: 'Atlanta Hartsfield-Jackson International Airport (ATL)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Atlanta.webp',
    hrefCard: 'Atlanta-International-Airport-ATL',
  },
  {
    price: 9.0,
    name: 'Los Angeles',
    fullName: 'Los Angeles International Airport (LAX)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/LosAngeles.webp',
    hrefCard: 'Los-Angeles-Airport-LAX',
  },
  {
    price: 13.98,
    name: 'Boston',
    fullName: 'Boston Logan International Airport (BOS)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Boston.webp',
    hrefCard: 'Boston-International-Airport-BOS',
  },
  {
    price: 6.5,
    name: 'San Francisco',
    fullName: 'San Francisco International Airport (SFO)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/SanFrancisco.webp',
    hrefCard: 'San-Francisco-International-Airport-SFO',
  },
  {
    price: 8.99,
    name: 'LaGuardia',
    fullName: 'LaGuardia International Airport (LGA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/LaGuardia.webp',
    hrefCard: 'LaGuardia-International-Airport-LGA',
  },
  {
    price: 3.99,
    name: 'Orlando',
    fullName: 'Orlando International Airport (MCO)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Orlando.webp',
    hrefCard: 'Orlando-International-Airport-MCO',
  },
  {
    price: 6.99,
    name: 'John F Kennedy',
    fullName: 'John F Kennedy International Airport (JFK)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/JFK.webp',
    hrefCard: 'John-F-Kennedy-International-Airport-JFK',
  },
  {
    price: 6.65,
    name: 'Fort Lauderdale',
    fullName: 'Fort Lauderdale International Airport (FLL)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/FortLauderdale.webp',
    hrefCard: 'Fort-Lauderdale-Airport-FLL',
  },
  {
    price: 5.99,
    name: 'Las Vegas',
    fullName: 'Las Vegas McCarran International Airport (LAS)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/LasVegas.webp',
    hrefCard: 'Las-Vegas-Airport-LAS',
  },
  {
    price: 5.99,
    name: 'Dallas',
    fullName: 'Dallas Fort Worth International Airport (DFW)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Dallas.webp',
    hrefCard: 'Dallas-International-Airport-DFW',
  },
  {
    price: 4.29,
    name: 'Baltimore',
    fullName: 'Baltimore Washington International Airport (BWI)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Baltimore.webp',
    hrefCard: 'Baltimore-Washington-Airport-BWI',
  },
  {
    price: 4.49,
    name: 'Charlotte',
    fullName: 'Charlotte Douglas International Airport (CLT)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Charlotte.webp',
    hrefCard: 'Charlotte-International-Airport-CLT',
  },
  {
    price: 10.0,
    name: 'Detroit',
    fullName: 'Detroit International Airport (DTW)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Detroit.webp',
    hrefCard: 'Detroit-International-Airport-DTW',
  },
  {
    price: 4.99,
    name: 'Houston',
    fullName: 'Houston International Airport (IAH)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Houston.webp',
    hrefCard: 'Houston-International-Airport-IAH',
  },
  {
    price: 4.99,
    name: 'Washington',
    fullName: 'Washington Dulles International Airport  (IAD)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Washington.webp',
    hrefCard: 'Washington-International-Airport-IAD',
  },
  {
    price: 5.75,
    name: 'Minneapolis',
    fullName: 'Minneapolis International Airport  (MSP)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Minneapolis.webp',
    hrefCard: 'Minneapolis-International-Airport-MSP',
  },
  {
    price: 5.99,
    name: 'Philadelphia',
    fullName: 'Philadelphia International Airport  (PHL)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Philadelphia.webp',
    hrefCard: 'Philadelphia-International-Airport-PHL',
  },
  {
    price: 9.99,
    name: 'Phoenix',
    fullName: 'Phoenix International Airport (PHX)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Phoenix.webp',
    hrefCard: 'Phoenix-International-Airport-PHX',
  },
  {
    price: 7.99,
    name: 'Seattle',
    fullName: 'Seattle Tacoma International Airport (SEA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Seattle.webp',
    hrefCard: 'Seattle-International-Airport-SEA',
  },
  {
    price: 4.8,
    name: 'Tampa',
    fullName: 'Tampa International Airport (TPA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Tampa.webp',
    hrefCard: 'Tampa-International-Airport-TPA',
  },
  {
    price: 9.99,
    name: 'Austin-Bergstrom',
    fullName: 'Austin-Bergstrom International Airport (AUS)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Austin.webp',
    hrefCard: 'Austin-Bergstrom-International-Airport-AUS',
  },
  {
    price: 4.95,
    name: 'Nashville',
    fullName: 'Nashville International Airport (BNA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Nashville.webp',
    hrefCard: 'Nashville-International-Airport-BNA',
  },
  {
    price: 3.55,
    name: 'Houston',
    fullName: 'Houston William P. Hobby Airport Parking (HOU)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Houston.webp',
    hrefCard: 'Houston - William - P - Hobby - HOU',
  },
  {
    price: 4.5,
    name: 'Salt Lake City',
    fullName: 'Salt Lake City International Airport (SLC)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/SaltLakeCity.webp',
    hrefCard: 'Salt-Lake-City-Airport-Parking-SLC',
  },
  {
    price: 7.0,
    name: 'Miami',
    fullName: 'Miami International Airport (MIA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Miami.webp',
    hrefCard: 'Miami-International-Airport-MIA',
  },
  {
    price: 6.5,
    name: 'Ronald Reagan',
    fullName: 'Ronald Reagan Washington National Airport (DCA)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/RonaldReagan.webp',
    hrefCard: 'Ronald-Reagan-Washington-National-Airport-DCA',
  },
  {
    price: 4.99,
    name: 'Portland',
    fullName: 'Portland International Airport (PDX)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/Portland.webp',
    hrefCard: 'Portland-International-Airport-PDX',
  },
  {
    price: 18.75,
    name: 'San Diego',
    fullName: 'San Diego International Airport (SAN)',
    imgCard: CDN2() + '/staticmyapp/carouselairports/SanDiego.webp',
    hrefCard: 'San-Diego-International-Airport-SAN',
  },
];
