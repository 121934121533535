export const reviewsCarousel = [
  {
    city: 'Denver',
    reviews: [
      {
        name: 'James L.',
        date: '10/26/2024',
        comment: 'Good experience as always',
      },
      {
        name: 'Tania K.',
        date: '10/23/2024',
        comment: 'Easy, safe and bus driver was friendly.',
      },
      { name: 'Gautam S.', date: '10/20/2024', comment: 'Very nice service' },
      {
        name: 'Nora F.',
        date: '10/19/2024',
        comment:
          'Great experience. Got picked up by my car and the shuttle from the airport was quick.',
      },
      {
        name: 'David K.',
        date: '10/16/2024',
        comment: 'Exceptional price and service as expected.',
      },
    ],
  },
  {
    city: 'Houston',
    reviews: [
      { name: 'Sherry W.', date: '10/30/2024', comment: 'I Love the Service' },
      {
        name: 'Eddie M.',
        date: '10/29/2024',
        comment: 'I love this spot I will be parking there everytime I need to',
      },
      {
        name: 'KEVAN J.',
        date: '10/29/2024',
        comment: 'Everything was excellent for me. Thank-you.',
      },
      {
        name: 'Sara G.',
        date: '10/07/2024',
        comment:
          'Easy reserving, access, and exit. Truly appreciate you wonderful and friendly drivers....they made my week and day.',
      },
      {
        name: 'Geovanni M.',
        date: '09/30/2024',
        comment:
          'Great drivers, great people, easy use of prepaid parking app!',
      },
    ],
  },
  {
    city: 'San Francisco',
    reviews: [
      {
        name: 'Brenda M.',
        date: '10/29/2024',
        comment:
          'This is our second time using this parking. It is so awesome and easy to access. Big savings too.',
      },
      {
        name: 'Ashmita T.',
        date: '10/09/2024',
        comment:
          'Great! Close to SFO. Gentleman in the lobby was very helpful on where to leave the car.',
      },
      {
        name: 'Alfred H.',
        date: '10/05/2024',
        comment:
          'Such an easy seamless process. Will definitely use again and recommend to others!',
      },
      {
        name: 'Nancy J.',
        date: '09/24/2024',
        comment: 'A great experience. Very convenient and efficient.',
      },
      {
        name: 'William P.',
        date: '09/23/2024',
        comment:
          'Easy to find a spot and well-lit area. Thanks, will book there again in the future.',
      },
    ],
  },
  {
    city: 'Atlanta',
    reviews: [
      {
        name: 'Kishore C.',
        date: '10/30/2024',
        comment: 'Excellent! Would recommend certainly.',
      },
      {
        name: 'Mercedes P.',
        date: '10/29/2024',
        comment: 'Very easy service and great price. Everyone is very helpful.',
      },
      {
        name: 'Aprile S.',
        date: '10/16/2024',
        comment: 'Always a great experience.',
      },
      {
        name: 'Colleen O.',
        date: '05/07/2024',
        comment: 'Conveniently located, great price & easy car pick-up.',
      },
      {
        name: 'Anthony P.',
        date: '08/24/2021',
        comment: 'Everything went very smoothly, thank you.',
      },
    ],
  },
  {
    city: 'Chicago',
    reviews: [
      {
        name: 'LeNedra W.',
        date: '10/30/2024',
        comment: 'Easy and economical.',
      },
      {
        name: 'Tim R.',
        date: '10/22/2024',
        comment:
          'Always great to drop off the car at a hotel and run in and out to the shuttle. It saves money and is super easy.',
      },
      {
        name: 'Kim J.',
        date: '10/16/2024',
        comment:
          'Very easy process from booking to getting to the airport! The directions given for arrival and departure were great as well!',
      },
      {
        name: 'Daniel P.',
        date: '09/14/2024',
        comment: 'All went smoothly. Would use again.',
      },
      {
        name: 'William L.',
        date: '04/12/2024',
        comment:
          'Very easy and convenient! The shuttle was very useful and reliable.',
      },
    ],
  },
];
