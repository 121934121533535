import React, { useState } from 'react';
import { Carousel, Rate } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { reviewsCarousel } from '@/utils/reviewsCarouselContent';

function ReviewsCarouselHome({ deviceType }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const flattenedReviews = reviewsCarousel.flatMap((city) => city.reviews);

  const reviewGroups = [];
  for (let i = 0; i < flattenedReviews.length; i += 5) {
    reviewGroups.push(flattenedReviews.slice(i, i + 5));
  }

  const handleAfterChange = (current) => {
    setActiveIndex(current);
  };

  return (
    <div className="flex flex-col">
      <h2 className="text-center text-4xl md:text-6xl mt-8 md:mt-4 mb-6 font-bold">
        Our customers love our service
      </h2>
      <p className="text-center text-xl md:text-2xl mb-6">
        See what they're saying about their experiences with us
      </p>
      <div className="pb-4 md:pb-8 flex flex-col">
        {deviceType === 'desktop' ? (
          <Carousel
            effect="scrollx"
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            className="overflow-visible"
            speed={500}
            arrows
            dots={false}
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            afterChange={handleAfterChange}
          >
            {reviewGroups.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className="flex flex-row justify-around md:gap-x-4"
              >
                {group.map((review, index) => (
                  <div
                    className="w-72 min-h-[15rem] border border-primary rounded-md p-4 space-y-4 flex flex-col justify-between"
                    key={index}
                  >
                    <Rate value={5} className="text-base mx-auto" />
                    <p className="text-base italic">"{review.comment}"</p>
                    <p className="font-bold text-sm">
                      {review.name},{' '}
                      <span className="font-normal italic">{review.date}</span>
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            effect="scrollx"
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            className="overflow-visible pb-4 px-6 md:px-28"
            speed={500}
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            afterChange={handleAfterChange}
          >
            {flattenedReviews.map((review, index) => (
              <div
                className="h-40 border border-primary rounded-md p-4 space-y-4 flex flex-col"
                key={index}
              >
                <Rate value={5} className="text-base mx-auto" />
                <p className="text-base h-1/2">{review.comment}</p>
                <p className="font-bold text-sm">
                  {review.name},{' '}
                  <span className="font-normal">{review.date}</span>
                </p>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}

export default ReviewsCarouselHome;
