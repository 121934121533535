import { isServer } from './isServer';

const DEFAULT_ID = '1';

export function getTestScope(variants = 2, df = DEFAULT_ID) {
  if (!isServer) {
    try {
      const randomId = parseInt(
        localStorage.getItem('randomId') ?? df
      ) as number;
      return randomId % variants;
    } catch (e) {
      // console.log(e)
    }
  }
  return 0;
}

export function homeAbTest(): number {
  const testScope = getTestScope(2, DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'home_ab_test',
      experimentNameC3: 'kayak-hotel',
      experimentIDC3: testScope == 1 ? 'variant1' : 'control',
    });
  }
  return testScope;
}

const CHECKOUT_ID = '0';

export function cardsAbTest(): number {
  const testScope = getTestScope(6, CHECKOUT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'confirmed_ab_test',
      experimentName: 'cards_abcdef',
      experimentID:
        testScope == 5
          ? 'variant'
          : testScope == 4
          ? 'variant4'
          : testScope == 3
          ? 'variant3'
          : testScope == 2
          ? 'variant2'
          : testScope == 1
          ? 'variant1'
          : 'control',
    });
  }
  return testScope;
}

const PARKINGAT_DEFAULT_ID = '1';

export function parkingAtAbTest(): number {
  const testScope = getTestScope(2, PARKINGAT_DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'parkingat_ab_test',
      experimentNameC2: 'newParkingAt',
      experimentIDC2: testScope ? 'variant1' : 'control',
    });
  }
  return testScope;
}
